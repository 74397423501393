import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Navigation from './components/Navigation';
import HookahFlavors from './components/HookahFlavors';
import Drinks from './components/Drinks';
import Desserts from './components/Desserts';
import Footer from './components/Footer';
import Host from './components/Host';
import Perks from './components/Perks';

function App() {
  const [activeTab, setActiveTab] = useState('hookah');

  const scrollToFlavors = () => {
    setActiveTab('hookah');
    const flavorsSection = document.getElementById('hookah-flavors');
    if (flavorsSection) {
      flavorsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'hookah':
        return <HookahFlavors />;
      case 'drinks':
        return <Drinks />;
      case 'desserts':
        return <Desserts />;
      case 'host' :
        return <Host />;
        case 'perks' :
        return <Perks />;
      default:
        return null;
    }
  };

  return (
    <div className="app">
      <Header scrollToFlavors={scrollToFlavors} />
      <Navigation activeTab={activeTab} setActiveTab={setActiveTab} />
      {renderContent()}
      <Footer/>
    </div>
  );
}

export default App;