import React from 'react';
import './Footer.css';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer>
      {/* Contact Information */}
      <div className="footer-content">
        <h3>Visit Us</h3>
        <p>3070 N High St, Columbus 43202</p>
        <p>
          <a href="mailto:kingsloungehookahinfo@gmail.com">
            kingsloungehookahinfo@gmail.com
          </a>
        </p>
        <p>
          <a href="tel:6149085309">(614) 908-5309</a>
        </p>
        <h4>Hours of Operation</h4>
        <p>Monday - Thursday: 10 AM - 2:30 AM</p>
        <p>Friday: 12 PM - 5 AM</p>
        <p>Saturday: 3 PM - 5 AM</p>
        <p>Sunday: 5 PM - 2:30 AM</p>
      </div>

      {/* Social Media */}
      <div className="footer-content">
        <h3>Follow Us</h3>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/profile.php?id=61560183987304&mibextid=LQQJ4d&rdid=3TYJ5uWTqLWL8GGJ&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F17qCDbhAsv%2F%3Fmibextid%3DLQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/kingsloungecbus/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
