import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./Host.css";

const Host = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_oelviwa", // Replace with your actual EmailJS Service ID
        "template_ju7dela", // Replace with your EmailJS Template ID
        formData,
        "LtshZEb0MJF08H7vl" // Replace with your EmailJS Public Key
      )
      .then(
        () => {
          alert("Inquiry sent successfully!");
          setFormData({ name: "", email: "", message: "" });
        },
        (error) => {
          console.error("Error sending inquiry:", error);
          alert("Failed to send inquiry. Please try again.");
        }
      );
  };

  return (
    <div className="content">
      <h2>Host Your Event at King’s Lounge</h2>

      <div className="host-section">
        <h3>Perfect for Any Occasion</h3>
        <ul>
          <li>Birthdays</li>
          <li>Networking & Meetings</li>
          <li>Private Events</li>
          <li>Launches</li>
          <li>Workshops & Training</li>
          <li>Pop-Ups & Exhibits</li>
          <li>Game Nights</li>
          <li>Live Shows</li>
        </ul>
      </div>

      <p className="host-contact">
        <strong>Book Your Event Today:</strong>
        <br />
        Ready to host your next event at King’s Lounge? Fill out the event inquiry form below, and we'll get back to you!
      </p>

      {/* Inquiry Form */}
      <form className="inquiry-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <textarea
          name="message"
          placeholder="Tell us about your event"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <button type="submit" className="contact-button">Send Inquiry</button>
      </form>
    </div>
  );
};

export default Host;