import React from "react";
import "./HookahFlavors.css";

const HookahFlavors = () => {
  return (
    <div className="content" id="hookah-flavors">
      <h2>Hookah Flavors</h2>

      {/* Pricing Section */}
      <div className="pricing-section">
        <h3>Pricing</h3>
        <p>Choose Regular or Premium versions for any flavor below:</p>
        <ul className="pricing-list">
          <li>
            <span>Regular Hookah</span>
          </li>
          <li>
            <span>Premium Hookah</span>
          </li>
          <li>
            <span>Special Hookah (Fruit Head)</span>
          </li>
        </ul>
      </div>

      {/* Flavors Section */}
      <div className="flavor-sections">
        <div className="flavor-section">
          <h3>King's Special</h3>
          <ul>
            <li>Kings Special</li>
            <li>Jelal Special</li>
          </ul>
        </div>

        <div className="flavor-section">
          <h3>Al Fakher</h3>
          <ul>
            <li>Gum Mint</li>
            <li>Blueberry Mint</li>
            <li>Mint</li>
            <li>Apple</li>
            <li>Peach</li>
            <li>Watermelon</li>
            <li>Mango</li>
            <li>Orange</li>
          </ul>
        </div>

        <div className="flavor-section">
          <h3>Starbuzz</h3>
          <ul>
            <li>Citrus Mist</li>
            <li>Watermelon Freeze</li>
            <li>Bluemist</li>
            <li>Mighty Freeze</li>
            <li>Sex on the Beach</li>
            <li>Safari Melon Dew</li>
            <li>Lady Pink</li>
            <li>Melon Blue</li>
            <li>Geisha</li>
          </ul>
        </div>

        <div className="flavor-section">
          <h3>Special Blends</h3>
          <ul>
            <li>Love 66</li>
            <li>Lady Killer</li>
            <li>Queen of Sex</li>
            <li>Code 69</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HookahFlavors;
