// Import Statements
import React from "react";
import "./Drinks.css";

// Importing Images for Beverages
import redbull from "../assets/rb.png";
import monster from "../assets/monster.png";
import water from "../assets/water.png";
import perrier from "../assets/Perrier.png"; // Renamed to lowercase for consistency
// Importing Images for Smoothies
import mango from "../assets/mango.png";
import mixedBerries from "../assets/mixedberries.png";
import Strawberrybanana from "../assets/stawberrybanana.png";

// Importing Images for Hot Drinks
import kingsTeaPot from "../assets/kingsTeaPot.png";

import kingsTeaCup from "../assets/KingsTeaCup.png";
import espressoImg from "../assets/espresso.png"; // Renamed to avoid conflict with variable name
import cappuccinoImg from "../assets/cappuccino.png";
import macchiatoImg from "../assets/macchiato.png";
import Latte from "../assets/Latte.png"
import ChaiTea from "../assets/chai.png"
import HotChoco from "../assets/HotChoc.png"
import Americano from "../assets/americano.png"


// (Optional) Importing Images for Other Beverages (Coke, Celsius, Popii)
// If you have specific images for these, import them similarly.
// Example:
import cokeImg from "../assets/coke.png";
import celsiusImg from "../assets/celsius.png";
import popiiImg from "../assets/popii.png";

const Drinks = () => {
  // Beverages Array
  const beverages = [
    { name: "Red Bull", image: redbull, price: "$3.99" },
    { name: "Monster", image: monster, price: "$3.99" },
    { name: "Water", image: water, price: "$2.00" },
    { name: "Perrier", image: perrier, price: "$3.99" },
    // If not, replace 'perrier' with the correct image variables
    { name: "Coke", image: cokeImg, price: "$3.99" }, // Replace 'perrier' with 'cokeImg' if available
    { name: "Celsius", image: celsiusImg, price: "$3.50" }, // Replace 'perrier' with 'celsiusImg' if available
    { name: "Popii", image: popiiImg, price: "$3.25" }, // Replace 'perrier' with 'popiiImg' if available
  ];

  // Smoothies Array
  const smoothies = [
    { name: "King's Special", image: mango, price: "$9.50" },
    { name: "Strawberry Banana", image: Strawberrybanana, price: "$9.50" },
    { name: "Berry Blast Smoothie", image: mixedBerries, price: "$9.50" },
  ];

  // Hot Drinks Array
  const hotDrinks = [
    { name: "King's Tea Pot", image: kingsTeaPot, price: "$5.00" },
    { name: "King's Tea Cup", image: kingsTeaCup, price: "$2.50" },
    { name: "Espresso", image: espressoImg, price: "$2.50" },
    { name: "Cappuccino", image: cappuccinoImg, price: "$5.00" },
    { name: "Macchiato", image: macchiatoImg, price: "$4.99" },
    { name: "Chai Tea", image: ChaiTea, price: "$4.99" },
    { name: "Latte", image: Latte, price: "$5.00" },
    { name: "Americano", image: Americano, price: "$2.50" },
    { name: "Hot Chocolate", image: HotChoco, price: "$3.50" }
  ];

  return (
    <div className="content">
      <h2>Drinks</h2>
      <div className="drinks-section">
        {/* Hot Drinks Section */}
        <div className="drinks-category">
          <h3>Hot Drinks</h3>
          <div className="menu-grid">
            {hotDrinks.map((hotDrink, index) => (
              <div className="menu-item" key={index}>
                <img src={hotDrink.image} alt={hotDrink.name} />
                <div className="text-overlay">
                  <p className="item-name">{hotDrink.name}</p>
                  <span className="item-price">{hotDrink.price}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Smoothies Section */}
        <div className="drinks-category">
          <h3>Smoothies</h3>
          <div className="menu-grid">
            {smoothies.map((smoothie, index) => (
              <div className="menu-item" key={index}>
                <img src={smoothie.image} alt={smoothie.name} />
                <div className="text-overlay">
                  <p className="item-name">{smoothie.name}</p>
                  <span className="item-price">{smoothie.price}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Beverages Section */}
        <div className="drinks-category">
          <h3>Beverages</h3>
          <div className="menu-grid">
            {beverages.map((drink, index) => (
              <div className="menu-item" key={index}>
                <img src={drink.image} alt={drink.name} />
                <div className="text-overlay">
                  <p className="item-name">{drink.name}</p>
                  <span className="item-price">{drink.price}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drinks;
