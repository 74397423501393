import React from "react";
import "./Perks.css";

const Perks = () => {
  return (
    <div className="content">
      <h2>Perks</h2>
      <div className="perks-section">
        <h3>Student Discounts</h3>
        <p>Enjoy exclusive discounts with a valid student ID.</p>
      </div>
      <div className="perks-section">
        <h3>Loyalty Program</h3>
        <p>Join our loyalty program via the Clover App and earn rewards with every purchase.</p>
      </div>
      <div className="perks-section">
        <h3>Happy Hours</h3>
        <p>Visit us between 5 PM - 8 PM for special discounts on select items.</p>
      </div>
    </div>
  );
};

export default Perks;